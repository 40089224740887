"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAliasCourse = void 0;
const Feed_1 = require("./Feed");
function getAliasCourse({ fbAPI, aliasId }) {
    return __awaiter(this, void 0, void 0, function* () {
        debugger;
        const courseAliasPath = fbAPI.getMultiPathCommon(`/courseAlias/${aliasId}`);
        const courseId = yield fbAPI.getValue(courseAliasPath);
        const courseDetails = yield fbAPI.getLMSCourseDetails(courseId);
        if (courseId && courseDetails) {
            const userCourseFeed = yield (0, Feed_1.getCourseUserFeed)({ fbAPI, courseId });
            if (!userCourseFeed) {
                yield (0, Feed_1.setCourseUserFeedForPublicCourse)({ fbAPI, courseId, authorId: courseDetails.authorId });
            }
            return {
                nuggetId: courseId,
                type: courseDetails.type
            };
        }
        return {
            error: 'Course with alias ID is not found.'
        };
    });
}
exports.getAliasCourse = getAliasCourse;

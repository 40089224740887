"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFormResponseDraft = exports.listenToUserFormDrafts = exports.getUserFormDraft = void 0;
function getUserFormDraftsNodeRef(fbAPI) {
    const userId = fbAPI.getLoggedInUserId();
    const path = fbAPI.getMultiPath(`forms/UserDrafts/${userId}`);
    return fbAPI.getNodeRef(path);
}
function getUserFormDraft(fbAPI) {
    return __awaiter(this, void 0, void 0, function* () {
        const nodeRef = getUserFormDraftsNodeRef(fbAPI);
        const drafts = (yield fbAPI.getValue(nodeRef)) || {};
        return drafts;
    });
}
exports.getUserFormDraft = getUserFormDraft;
const listenToUserFormDrafts = (fbAPI, cb) => {
    const nodeRef = getUserFormDraftsNodeRef(fbAPI);
    return fbAPI.listenOnNodeRef(nodeRef, "value", (snap) => {
        const value = snap.val();
        cb(value);
    });
};
exports.listenToUserFormDrafts = listenToUserFormDrafts;
const isFormResponseDraft = (fbAPI, formId, responseId) => __awaiter(void 0, void 0, void 0, function* () {
    const path = `${fbAPI.getCommonPath("currentUserFormDrafts")}/${formId}/${responseId}`;
    const value = yield fbAPI.getValue(fbAPI.getNodeRef(path));
    return !!value;
});
exports.isFormResponseDraft = isFormResponseDraft;

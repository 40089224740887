"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToUserActionRequired = void 0;
const subscribeToUserActionRequired = (dbApi, cb) => {
    const userActionRequiredRef = dbApi.getNodeRef(`${dbApi.getCommonPath("currentUserFormLookups")}/actionOn`);
    const listenerHandler = (type) => (feedItemSnapShot) => __awaiter(void 0, void 0, void 0, function* () {
        const feed = (yield feedItemSnapShot.val()) || null;
        const id = feedItemSnapShot.key;
        if (!id) {
            return;
        }
        if (type === 'child_removed') {
            return cb(id, null);
        }
        else if (feed && id && feed.remindedAt && feed.completeBy) {
            const { lastFilled = 0, remindedAt, completeBy } = feed;
            const now = Date.now();
            if (!(lastFilled >= remindedAt && lastFilled <= completeBy) &&
                now <= completeBy) {
                return cb(id, feed);
            }
            return cb(id, null);
        }
        else if (id) {
            return cb(id, null);
        }
    });
    const unSubCC = dbApi.listenOnNodeRef(userActionRequiredRef, "child_changed", listenerHandler("child_changed"));
    const unSubCA = dbApi.listenOnNodeRef(userActionRequiredRef, "child_added", listenerHandler("child_added"));
    const unSubCR = dbApi.listenOnNodeRef(userActionRequiredRef, "child_removed", listenerHandler("child_removed"));
    return () => {
        unSubCC();
        unSubCA();
        unSubCR();
    };
};
exports.subscribeToUserActionRequired = subscribeToUserActionRequired;

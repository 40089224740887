"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanUpSubscribedFormsCategories = exports.getFormNugget = exports.subscribeToUserForms = void 0;
const analytics_1 = require("./analytics");
let cleanups = [];
function getUserFormsFeedRef(fbAPI) {
    const path = (0, analytics_1.getUserFormsFeedPath)(fbAPI);
    return fbAPI.getNodeRef(path);
}
function getFormNuggetCategories(fbAPI, nuggetId, cb) {
    const path = fbAPI.getMultiPath(`forms/nuggetCategories/${nuggetId}`);
    return fbAPI.listenOnNodeRef(fbAPI.getNodeRef(path), "value", (snap) => {
        cb(snap.val());
    });
}
const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";
function subscribeToUserForms(fbAPI, cb) {
    const userFeedRef = getUserFormsFeedRef(fbAPI);
    const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "createdAt");
    function listenerHandler(listenerEvenType) {
        return (feedItemSnapShot) => __awaiter(this, void 0, void 0, function* () {
            const feed = (yield feedItemSnapShot.val()) || null;
            const id = feedItemSnapShot.key;
            if (!id) {
                return;
            }
            if (listenerEvenType === typeCR) {
                return cb(id, null);
            }
            else if (feed && id && feed.isShared === true) {
                if (!feed.isReceived && listenerEvenType === typeCA) {
                    // Raise received analytics...
                    (0, analytics_1.raiseAnalytics)({
                        fbAPI,
                        type: "received",
                        formItem: Object.assign(Object.assign({}, feed), { id }),
                    });
                }
                cleanups.push(getFormNuggetCategories(fbAPI, id, (nuggetCategories) => {
                    const folderIds = Object.keys(nuggetCategories || {}).filter((categoryId) => nuggetCategories[categoryId]);
                    cb(id, Object.assign(Object.assign({}, feed), { id, folderIds }));
                }));
                return;
            }
            else if (id) {
                return cb(id, null);
            }
        });
    }
    const listenerHandlerCC = listenerHandler(typeCC);
    const listenerHandlerCA = listenerHandler(typeCA);
    const listenerHandlerCR = listenerHandler(typeCR);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCC, listenerHandlerCC);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCA, listenerHandlerCA);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCR, listenerHandlerCR);
    return () => Promise.all([
        fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
        fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
        fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}
exports.subscribeToUserForms = subscribeToUserForms;
const getFormNugget = (fbAPI, formId) => {
    const formNuggetRef = fbAPI.getNodeRef(fbAPI.getCommonPath("formNugget"));
    return fbAPI.getValue(fbAPI.getChild(formNuggetRef, formId));
};
exports.getFormNugget = getFormNugget;
const cleanUpSubscribedFormsCategories = () => {
    for (const c of cleanups) {
        c();
    }
    cleanups = [];
};
exports.cleanUpSubscribedFormsCategories = cleanUpSubscribedFormsCategories;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGamificationNuggetTypes = exports.getUtcOffsetSec = exports.getTimezoneIdentifier = exports.checkForLocationPermission = exports.getLocation = void 0;
const getLocation = () => new Promise((res, rej) => {
    try {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((result) => {
                res({
                    lat: result.coords.latitude,
                    lng: result.coords.longitude,
                });
            }, (err) => {
                console.log(err);
                if (err.code === 1) {
                    rej(new Error("LocationNotAllowed"));
                }
                rej(err);
            });
        }
        else {
            throw new Error("LocationNotAvailable");
        }
    }
    catch (err) {
        console.log(err);
        rej(err);
    }
});
exports.getLocation = getLocation;
const checkForLocationPermission = (props) => __awaiter(void 0, void 0, void 0, function* () {
    const { onGranted, onPrompt, onDenied } = props !== null && props !== void 0 ? props : {};
    const permissionResultHandler = (result, source = "initial") => {
        if (result.state === "granted") {
            onGranted && onGranted(source);
        }
        else if (result.state === "prompt") {
            onPrompt && onPrompt(source);
        }
        else if (result.state === "denied") {
            onDenied && onDenied(source);
        }
        return result.state;
    };
    const handleChange = function () {
        permissionResultHandler(this, "onchange");
    };
    const permissionResult = yield navigator.permissions.query({
        name: "geolocation",
    });
    const currentState = permissionResultHandler(permissionResult);
    permissionResult.addEventListener("change", handleChange);
    return [
        currentState,
        () => permissionResult.removeEventListener("change", handleChange),
    ];
});
exports.checkForLocationPermission = checkForLocationPermission;
const getTimezoneIdentifier = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
exports.getTimezoneIdentifier = getTimezoneIdentifier;
const getUtcOffsetSec = () => new Date().getTimezoneOffset() * -60;
exports.getUtcOffsetSec = getUtcOffsetSec;
const getGamificationNuggetTypes = (classificationType) => {
    switch (classificationType) {
        case "form":
            return { type: "forms", subType: "forms" };
        default:
            return null;
    }
};
exports.getGamificationNuggetTypes = getGamificationNuggetTypes;

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanUpSubscribedReceivedForms = exports.subscribeToUserReceivedForms = exports.getUserReceivedResponses = exports.isResponseStillInUserReceivedTab = void 0;
const database_1 = require("firebase/database");
function getUserReceivedForms(fbAPI) {
    const userId = fbAPI.getLoggedInUserId();
    const path = fbAPI.getMultiPath(`/forms/Lookups/${userId}/receivedForms`);
    return fbAPI.getNodeRef(path);
}
const isResponseStillInUserReceivedTab = (fbAPI, nuggetId, responseId) => __awaiter(void 0, void 0, void 0, function* () {
    const userId = fbAPI.getLoggedInUserId();
    const path = fbAPI.getMultiPath(`/forms/Lookups/${userId}/receivedResponses/${nuggetId}/${responseId}`);
    const value = yield fbAPI.getValue(fbAPI.getNodeRef(path));
    return !!value;
});
exports.isResponseStillInUserReceivedTab = isResponseStillInUserReceivedTab;
function getUserReceivedResponses(fbAPI, nuggetId, limit = false, cb) {
    const userId = fbAPI.getLoggedInUserId();
    const path = fbAPI.getMultiPath(`/forms/Lookups/${userId}/receivedResponses/${nuggetId}`);
    const pathRef = fbAPI.getNodeRef(path);
    const orderByQueryRef = fbAPI.queryOrderByChild(pathRef, "createdAt");
    const endAtQueryRef = fbAPI.queryValue(orderByQueryRef, "endAt", Date.now());
    const limitToLastQueryRef = limit
        ? (0, database_1.query)(endAtQueryRef, (0, database_1.limitToLast)(5))
        : endAtQueryRef;
    const handler = (snap) => {
        var _a;
        const recentReceivedResponses = (_a = snap.val()) !== null && _a !== void 0 ? _a : {};
        const data = Object.entries(recentReceivedResponses)
            .map(([responseId, response]) => {
            return Object.assign({ id: responseId }, response);
        })
            .sort((a, b) => (b.createdAt || 0) - (a.createdAt || 0));
        cb(data);
    };
    return fbAPI.listenOnNodeRef(limitToLastQueryRef, "value", handler);
}
exports.getUserReceivedResponses = getUserReceivedResponses;
const typeCC = "child_changed";
const typeCA = "child_added";
const typeCR = "child_removed";
let cleanups = [];
function subscribeToUserReceivedForms(fbAPI, cb) {
    const userFeedRef = getUserReceivedForms(fbAPI);
    const orderByQueryRef = fbAPI.queryOrderByChild(userFeedRef, "receivedAt");
    function listenerHandler(_listenerEvenType) {
        return (receivedFormItemSnapShot) => __awaiter(this, void 0, void 0, function* () {
            const receivedForm = receivedFormItemSnapShot.val();
            const formId = receivedFormItemSnapShot.key;
            if (!formId) {
                return;
            }
            if (_listenerEvenType === typeCR) {
                return cb(formId, null);
            }
            else if (receivedForm) {
                cleanups.push(getUserReceivedResponses(fbAPI, formId, true, receivedResponsesListenerHandler(formId, receivedForm)));
            }
            else {
                cb(formId, null);
            }
        });
    }
    const receivedResponsesListenerHandler = (formId, receivedForm) => (data) => {
        const sentFormItem = Object.assign(Object.assign({ id: formId, hasMoreData: data.length > 4 }, receivedForm), { pending: Object.keys(receivedForm.status || {}).filter((id) => { var _a; return ((_a = receivedForm === null || receivedForm === void 0 ? void 0 : receivedForm.status) === null || _a === void 0 ? void 0 : _a[id]) === true; }).length, data: data.splice(0, 4) });
        cb(formId, sentFormItem);
    };
    const listenerHandlerCC = listenerHandler(typeCC);
    const listenerHandlerCA = listenerHandler(typeCA);
    const listenerHandlerCR = listenerHandler(typeCR);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCC, listenerHandlerCC);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCA, listenerHandlerCA);
    fbAPI.listenOnNodeRef(orderByQueryRef, typeCR, listenerHandlerCR);
    return () => Promise.all([
        fbAPI.offNodeRef(userFeedRef, typeCC, listenerHandlerCC),
        fbAPI.offNodeRef(userFeedRef, typeCA, listenerHandlerCA),
        fbAPI.offNodeRef(userFeedRef, typeCR, listenerHandlerCR),
    ]);
}
exports.subscribeToUserReceivedForms = subscribeToUserReceivedForms;
const cleanUpSubscribedReceivedForms = () => {
    for (const c of cleanups) {
        c();
    }
    cleanups = [];
};
exports.cleanUpSubscribedReceivedForms = cleanUpSubscribedReceivedForms;

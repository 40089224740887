"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseAPI = void 0;
const app_1 = require("firebase/app");
const database_1 = require("firebase/database");
const auth_1 = require("firebase/auth");
const storage_1 = require("firebase/storage");
const js_base64_1 = require("js-base64");
const lodash_1 = require("lodash");
const utils_1 = require("./utils");
const LMSAnalytics_1 = require("./LMSAnalytics");
const throwErrorWhenTimeOut = (ms, message = "Request timeout!") => {
    return new Promise((_res, rej) => {
        setTimeout(() => rej(new Error(message)), ms);
    });
};
const noop = () => { };
class FirebaseAPI {
    constructor(config, appName, authChangedCB) {
        this.organization = "na";
        this.user = null;
        const fbConfig = config;
        this.config = fbConfig;
        const app = (0, app_1.initializeApp)(config, appName);
        this.app = app;
        this.db = (0, database_1.getDatabase)(app);
        this.auth = (0, auth_1.getAuth)(app);
        this.storage = (0, storage_1.getStorage)(app);
        this.auth.onAuthStateChanged((authUser) => __awaiter(this, void 0, void 0, function* () {
            this.user = authUser;
            console.log(appName, authUser);
            if (authUser) {
                const _b = yield FirebaseAPI.getTokenUserDetails(authUser), { organization: org } = _b, details = __rest(_b, ["organization"]);
                this.organization = org !== null && org !== void 0 ? org : "na";
                authChangedCB && authChangedCB(Object.assign(Object.assign({}, authUser), details));
            }
            else {
                this.organization = "na";
                authChangedCB && authChangedCB(authUser);
            }
        }));
    }
    static decodeJWT(token) {
        return JSON.parse(js_base64_1.Base64.decode(token.split(".")[1] || "{}"));
    }
    static encodeBase64(value) {
        return js_base64_1.Base64.encode(value);
    }
    static decodeBase64(value) {
        return js_base64_1.Base64.decode(value);
    }
    getNodeRef(nodePath) {
        return (0, database_1.ref)(this.db, nodePath);
    }
    pushToNode(nodePath, payload) {
        return (0, database_1.push)(this.getNodeRef(nodePath), payload);
    }
    getChild(ref, path) {
        return (0, database_1.child)(ref, path);
    }
    queryOrderByChild(ref, childName) {
        return (0, database_1.query)(ref, (0, database_1.orderByChild)(childName));
    }
    queryValue(queryRef, type, value, key) {
        let queryFunction = database_1.equalTo;
        switch (type) {
            case "startAt":
                queryFunction = database_1.startAt;
                break;
            case "startAfter":
                queryFunction = database_1.startAfter;
                break;
            case "endBefore":
                queryFunction = database_1.endBefore;
                break;
            case "endAt":
                queryFunction = database_1.endAt;
                break;
            case "equalTo":
                queryFunction = database_1.equalTo;
                break;
            default:
                break;
        }
        return (0, database_1.query)(queryRef, queryFunction(value, key));
    }
    getMultiPathCommon(nodePath = "") {
        nodePath = nodePath ? `/${nodePath}` : "";
        return (0, database_1.ref)(this.db, `clientOrganizations/${this.organization}${nodePath}`);
    }
    getPublishedShiftNode(shiftDay) {
        return this.getMultiPathCommon(`shifts/days/${shiftDay}`);
    }
    listenOnNodeRef(queryNodeRef, type, cb) {
        let listenerFunction;
        switch (type) {
            case "value":
                listenerFunction = database_1.onValue;
                break;
            case "child_added":
                listenerFunction = database_1.onChildAdded;
                break;
            case "child_changed":
                listenerFunction = database_1.onChildChanged;
                break;
            case "child_removed":
                listenerFunction = database_1.onChildRemoved;
                break;
            case "child_moved":
                listenerFunction = database_1.onChildMoved;
                break;
            default:
                break;
        }
        if (listenerFunction) {
            return listenerFunction(queryNodeRef, cb);
        }
        return noop;
    }
    offNodeRef(ref, type, cb) {
        (0, database_1.off)(ref, type, cb);
    }
    getPushKey(nodePath = "/tmp") {
        return (0, database_1.push)(this.getNodeRef(nodePath)).key;
    }
    getPushKeyRef(nodeRef = this.getNodeRef("/tmp")) {
        return (0, database_1.push)(nodeRef).key;
    }
    fbUpdate(nodePath, payload) {
        return (0, database_1.update)(this.getNodeRef(nodePath), payload);
    }
    fbSet(nodePath, payload) {
        return (0, database_1.set)(this.getNodeRef(nodePath), payload);
    }
    getLoggedInUserId() {
        var _b, _c;
        return (_c = (_b = this.auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : null;
    }
    waitForRPCResponse(responseNodePath) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, _reject) => {
                const nodeRef = this.getNodeRef(responseNodePath);
                const handler = (snap) => __awaiter(this, void 0, void 0, function* () {
                    const response = snap.val() || {};
                    const { count = 0, results = [], success, result } = response;
                    if ((count === (results.count || results.length) && success) ||
                        result ||
                        success === false ||
                        result === false) {
                        (0, database_1.off)(nodeRef, "value", handler);
                        resolve(response);
                        try {
                            yield (0, database_1.set)(nodeRef, null);
                        }
                        catch (err) {
                            console.log(err);
                        }
                    }
                });
                (0, database_1.onValue)(nodeRef, handler);
            });
        });
    }
    callRPC(nodePath, payload, timeoutInSecond = 15) {
        return __awaiter(this, void 0, void 0, function* () {
            const RPCCall = this.callRPCWithoutTimeout(nodePath, payload);
            const timeout = throwErrorWhenTimeOut(timeoutInSecond * 1000, "There seems to be a problem with your network connection!");
            try {
                return yield Promise.race([RPCCall, timeout]);
            }
            catch (err) {
                console.error(err);
                throw err;
            }
        });
    }
    callRPCWithoutTimeout(nodePath, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const pushKey = this.getPushKey(nodePath);
            const requestNodePath = `${nodePath}/request/${pushKey}`;
            const responseNodePath = `${nodePath}/response/${pushKey}`;
            // Place the request
            yield this.fbUpdate(requestNodePath, Object.assign(Object.assign({ organization: this.organization }, payload), { createdAt: Date.now(), authUUID: this.getLoggedInUserId(), userId: this.getLoggedInUserId() }));
            try {
                return yield this.waitForRPCResponse(responseNodePath);
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        });
    }
    signInWithCustomToken(token) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, auth_1.signInWithCustomToken)(this.auth, token);
        });
    }
    signOut() {
        return (0, auth_1.signOut)(this.auth);
    }
    getSignInToken(scoobyToken, organization, newToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                scoobyToken,
                organization,
                idToken: newToken,
            };
            const results = yield this.callRPC("RPC/token", payload);
            return (0, lodash_1.get)(results, "result", false)
                ? (0, lodash_1.get)(results, "token", "")
                : "";
        });
    }
    signInToOrg(scoobyToken, organization, newToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const orgToken = yield this.getSignInToken(scoobyToken, organization, newToken);
            yield this.signInWithCustomToken(orgToken);
        });
    }
    getServerTimestamp() {
        return (0, database_1.serverTimestamp)();
    }
    getUserAttendanceCacheNode(userId) {
        return this.getMultiPathCommon(`attendanceCacheData/${userId}`);
    }
    getCurrentUserAttendanceCacheNode() {
        var _b;
        return this.getUserAttendanceCacheNode(((_b = this.user) === null || _b === void 0 ? void 0 : _b.uid) || "na");
    }
    getUserAttendanceNode(userId) {
        return this.getMultiPathCommon(`attendanceEvents/${userId}`);
    }
    getCurrentUserAttendanceNode() {
        var _b;
        return this.getUserAttendanceCacheNode(((_b = this.user) === null || _b === void 0 ? void 0 : _b.uid) || "na");
    }
    getMultiPath(nodePath) {
        const orgPath = `clientOrganizations/${this.organization}`;
        if (nodePath) {
            return `${orgPath}/${nodePath}`;
        }
        return orgPath;
    }
    getCommonPath(type) {
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        switch (type) {
            case "shareRequests":
                return "newShareRequests";
            case "shifts":
                return this.getMultiPath("shifts");
            case "attendanceEvents":
                return this.getMultiPath("attendanceEvents");
            case "attendanceEvents":
                return this.getMultiPath(`attendanceEvents`);
            case "currentUserAttendanceEvents":
                return this.getMultiPath(`attendanceEvents/${((_b = this.user) === null || _b === void 0 ? void 0 : _b.uid) || "na"}`);
            case "attendanceCache":
                return this.getMultiPath(`attendanceCacheData`);
            case "currentUserAttendanceCache":
                return this.getMultiPath(`attendanceCacheData/${((_c = this.user) === null || _c === void 0 ? void 0 : _c.uid) || "na"}`);
            case "users":
                return this.getMultiPath("users");
            case "formNugget":
                return this.getMultiPath("forms/nuggets");
            case "formUserResponses":
                return this.getMultiPath("forms/UserResponses");
            case "currentUserFormResponses":
                return this.getMultiPath(`forms/UserResponses/${(_e = (_d = this.user) === null || _d === void 0 ? void 0 : _d.uid) !== null && _e !== void 0 ? _e : "na"}`);
            case "currentUserFormDrafts":
                return this.getMultiPath(`forms/UserDrafts/${(_g = (_f = this.user) === null || _f === void 0 ? void 0 : _f.uid) !== null && _g !== void 0 ? _g : "na"}`);
            case "currentUserFormLookups":
                return this.getMultiPath(`forms/Lookups/${(_j = (_h = this.user) === null || _h === void 0 ? void 0 : _h.uid) !== null && _j !== void 0 ? _j : "na"}`);
            case "currentUserFormReceivedResponses":
                return this.getMultiPath(`forms/Lookups/${(_l = (_k = this.user) === null || _k === void 0 ? void 0 : _k.uid) !== null && _l !== void 0 ? _l : "na"}/receivedResponses`);
            case "currentUserFormReceivedForms":
                return this.getMultiPath(`forms/Lookups/${(_o = (_m = this.user) === null || _m === void 0 ? void 0 : _m.uid) !== null && _o !== void 0 ? _o : "na"}/receivedForms`);
            case "groupMembers":
                return this.getMultiPath("groupMembers");
            case "gamifications":
                return this.getMultiPath("gamification/points");
            case "gamificationRequests":
                return "userEvents";
            case "formPaperTrails":
                return this.getMultiPath("forms/FormPaperTrail");
            default:
                return this.getMultiPath();
        }
    }
    getValue(nodeRef) {
        return __awaiter(this, void 0, void 0, function* () {
            const snapshot = yield (0, database_1.get)(nodeRef);
            return snapshot.val();
        });
    }
    getUserDetails(userId) {
        return __awaiter(this, void 0, void 0, function* () {
            const user = yield this.getValue(this.getMultiPathCommon(`users/${userId}`));
            if (user) {
                return Object.assign(Object.assign({}, user), { userId });
            }
            return user;
        });
    }
    getLMSDetails(type, courseId) {
        switch (type) {
            case "myCourseFeed":
                return this.getMultiPathCommon(`userFeed/${this.getLoggedInUserId()}/courses`);
            case "myCourseFeedById":
                return this.getMultiPathCommon(`userFeed/${this.getLoggedInUserId()}/courses/${courseId}`);
            case "completedCourseFeed":
                return this.getMultiPathCommon(`completedFeed/${this.getLoggedInUserId()}/courses`);
            case "courseByIdDetails":
                return this.getMultiPathCommon(`nuggets/courses/${courseId}`);
            case "courseByIdPayload":
                return this.getMultiPathCommon(`nuggets/courses/${courseId}/payload`);
            case "journeyCourses":
                return this.getMultiPathCommon(`nuggets/courses/${courseId}/details`);
        }
    }
    getLMSCourseDetails(courseId) {
        return this.getValue(this.getLMSDetails("courseByIdDetails", courseId));
    }
    getLMSCoursePayload(courseId) {
        return this.getValue(this.getLMSDetails("courseByIdPayload", courseId));
    }
    getLMSJourneyCourses(courseId) {
        return this.getValue(this.getLMSDetails("journeyCourses", courseId));
    }
    getCompletedCourses(courseId) {
        return this.getValue(this.getLMSDetails("myCourseFeedById", courseId));
    }
    getUserCourseFeedItems({ lastCreatedAt, limit = 16, courseType, }) {
        const pathRef = this.getLMSDetails(courseType);
        const orderByQueryRef = this.queryOrderByChild(pathRef, "createdAt");
        const endAtQueryRef = this.queryValue(orderByQueryRef, "endAt", lastCreatedAt || Date.now());
        const limitToLastQueryRef = (0, database_1.query)(endAtQueryRef, (0, database_1.limitToLast)(limit));
        return this.getValue(limitToLastQueryRef);
    }
    listenForAddRemoveCourseFeedItems(cb, courseType) {
        const pathRef = this.getLMSDetails(courseType);
        const orderByQueryRef = this.queryOrderByChild(pathRef, "createdAt");
        const endAtQueryRef = this.queryValue(orderByQueryRef, "startAfter", Date.now() - 1000);
        const unSubAdded = this.listenOnNodeRef(endAtQueryRef, "child_added", (snapshot) => {
            cb([snapshot.key, snapshot.val()]);
        });
        const unSubRemoved = this.listenOnNodeRef(pathRef, "child_removed", (snapshot) => {
            cb([snapshot.key, null]);
        });
        return () => {
            unSubAdded && unSubAdded();
            unSubRemoved && unSubRemoved();
        };
    }
    listenForUserFeedChangedFinishedCount(cb, courseId) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathRef = this.getMultiPathCommon(`userFeed/${this.getLoggedInUserId()}/courses/${courseId}/finishedCount`);
            this.offNodeRef(pathRef, "value");
            this.listenOnNodeRef(pathRef, "value", (snapshot) => {
                cb([snapshot.key, snapshot.val()]);
            });
        });
    }
    listenForProgressLMS(cb, courseId) {
        return __awaiter(this, void 0, void 0, function* () {
            const pathRef = this.getMultiPathCommon(`progressLMS/${this.getLoggedInUserId()}/${courseId}`);
            const pathQuizRef = this.getMultiPathCommon(`progressQuizLMS/${this.getLoggedInUserId()}/${courseId}`);
            this.offNodeRef(pathRef, "value");
            const progressLMSRes = this.listenOnNodeRef(pathRef, "value", (snapshot) => {
                cb(["progressLMS", snapshot.val()]);
            });
            this.offNodeRef(pathQuizRef, "value");
            const progressQuizLMSRes = this.listenOnNodeRef(pathQuizRef, "value", (snapshot) => {
                cb(["progressQuizLMS", snapshot.val()]);
            });
            return () => {
                progressLMSRes && progressLMSRes();
                progressQuizLMSRes && progressQuizLMSRes();
            };
        });
    }
    setUserFeedCourseInfo(type, payload) {
        var _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            const { courseId, userFeed, lessonId, cardId, prevCardId, selectedLanguage, shareId, selectedIndex, selectedOption, scoreCard, dt, lang, sessionId, progSec, attempt, scromProgress, feedType, journeyId, data, analyticsCBPayload, } = payload;
            let path;
            switch (type) {
                case "SetLanguage":
                    path = `clientOrganizations/${this.organization}/${feedType}/${this.getLoggedInUserId()}/courses/${journeyId !== null && journeyId !== void 0 ? journeyId : courseId}/lang`;
                    yield this.fbSet(path, selectedLanguage);
                    break;
                case "JourneyStarted": {
                    const journeyStartedPayload = {
                        journeyId,
                        dt,
                        orgId: this.organization,
                        shareId,
                        sessionId,
                        st: this.getServerTimestamp(),
                        type: "started",
                        lang,
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "JourneyStarted", fbAPI: this, payload: journeyStartedPayload });
                    break;
                }
                case "JourneyConsumed": {
                    path = `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${journeyId}/consumedAt`;
                    this.fbSet(path, this.getServerTimestamp());
                    break;
                }
                case "JourneyCompleted": {
                    const journeyCompletedPayload = {
                        journeyId,
                        dt,
                        lang,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "ended",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "JourneyCompleted", fbAPI: this, payload: journeyCompletedPayload });
                    break;
                }
                case "CourseStarted": {
                    const courseStartedPayload = {
                        courseId,
                        dt,
                        orgId: this.organization,
                        shareId,
                        sessionId,
                        st: this.getServerTimestamp(),
                        type: "started",
                        lang,
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CourseStarted", fbAPI: this, payload: courseStartedPayload });
                    break;
                }
                case "CourseConsumed": {
                    const courseConsumedPayload = {
                        courseId,
                        dt,
                        lang,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "consumed",
                        uuid: this.getLoggedInUserId(),
                    };
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/consumedAt`
                        : `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${courseId}/consumedAt`;
                    this.fbSet(path, this.getServerTimestamp());
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CourseConsumed", fbAPI: this, payload: courseConsumedPayload });
                    break;
                }
                case "CourseCompleted": {
                    const courseCompletedPayload = {
                        courseId,
                        dt,
                        lang,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "ended",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CourseCompleted", fbAPI: this, payload: courseCompletedPayload });
                    break;
                }
                case "LessonStarted": {
                    const lessonStartedPayload = {
                        courseId,
                        moduleId: lessonId,
                        dt,
                        lang,
                        orgId: this.organization,
                        shareId,
                        sessionId,
                        st: this.getServerTimestamp(),
                        type: "started",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "LessonStarted", fbAPI: this, payload: lessonStartedPayload });
                    break;
                }
                case "LessonConsumed": {
                    const lessonConsumedPayload = {
                        courseId,
                        dt,
                        lang,
                        moduleId: lessonId,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "consumed",
                        uuid: this.getLoggedInUserId(),
                    };
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/consumedAt`
                        : `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/consumedAt`;
                    this.fbSet(path, this.getServerTimestamp());
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "LessonStarted", fbAPI: this, payload: lessonConsumedPayload });
                    break;
                }
                case "LessonCompleted": {
                    const lessonCompletedPayload = {
                        courseId,
                        moduleId: lessonId,
                        dt,
                        lang,
                        orgId: this.organization,
                        shareId,
                        sessionId,
                        st: this.getServerTimestamp(),
                        type: "ended",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "LessonCompleted", fbAPI: this, payload: lessonCompletedPayload });
                    break;
                }
                case "CardStarted": {
                    const cardStartedPayload = {
                        cardId,
                        prevCardId,
                        courseId,
                        moduleId: lessonId,
                        dt,
                        lang,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "started",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CardStarted", fbAPI: this, payload: cardStartedPayload });
                    break;
                }
                case "CardConsumed": {
                    const cardConsumedPayload = {
                        cardId,
                        courseId,
                        dt,
                        lang,
                        moduleId: lessonId,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "consumed",
                        uuid: this.getLoggedInUserId(),
                    };
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}/consumedAt`
                        : `clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/consumedAt`;
                    this.fbSet(path, this.getServerTimestamp());
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CardConsumed", fbAPI: this, payload: cardConsumedPayload });
                    break;
                }
                case "CardCompleted": {
                    const cardCompletedPayload = {
                        cardId,
                        courseId,
                        dt,
                        lang,
                        moduleId: lessonId,
                        orgId: this.organization,
                        progSec,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "ended",
                        uuid: this.getLoggedInUserId(),
                    };
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "CardCompleted", fbAPI: this, payload: cardCompletedPayload });
                    break;
                }
                case "QuizAnswer": {
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}/res/${selectedIndex}`
                        : `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/res/${selectedIndex}`;
                    const quizAnswerPayload = {
                        c: this.getServerTimestamp(),
                        r: selectedOption,
                    };
                    yield this.fbSet(path, quizAnswerPayload);
                    const analyticsPayload = {
                        attempt,
                        cardId,
                        courseId,
                        journeyId: journeyId !== null && journeyId !== void 0 ? journeyId : null,
                        data: { question: selectedIndex, response: selectedOption },
                        dt,
                        moduleId: lessonId,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "answer",
                        uuid: this.getLoggedInUserId(),
                    };
                    path = `/LMSQuizResponseRequests/${this.getPushKey()}`;
                    yield this.fbSet(path, analyticsPayload);
                    break;
                }
                case "QuizAttemptInc": {
                    const attemptsPath = journeyId
                        ? `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}`
                        : `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}`;
                    yield this.fbUpdate(attemptsPath, {
                        attempted: (0, database_1.increment)(1),
                    });
                    break;
                }
                case "QuizScoreUpdate": {
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${journeyId}/${shareId}/${courseId}/${lessonId}/cardProgress/${cardId}`
                        : `clientOrganizations/${this.organization}/progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}`;
                    if (scoreCard) {
                        yield this.fbUpdate(path, { s: scoreCard });
                    }
                    break;
                }
                case "UserFeedReceived": {
                    for (const feedCourseId in userFeed) {
                        if (feedCourseId !== "undefined") {
                            // @ts-ignore
                            const element = userFeed[feedCourseId];
                            if (!element.isReceived) {
                                const userFeedReceivedPayload = {
                                    courseId: feedCourseId,
                                    dt: Date.now(),
                                    orgId: this.organization,
                                    shareId: element === null || element === void 0 ? void 0 : element.shareId,
                                    st: this.getServerTimestamp(),
                                    type: "received",
                                    uuid: this.getLoggedInUserId(),
                                };
                                yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "Received", fbAPI: this, payload: userFeedReceivedPayload });
                                /* Update isReceived: true */
                                const userFeedPath = `clientOrganizations/${this.organization}/userFeed/${this.getLoggedInUserId()}/courses/${feedCourseId}`;
                                yield this.fbUpdate(userFeedPath, {
                                    isReceived: true,
                                });
                            }
                        }
                    }
                    break;
                }
                case "MoveUserFeedToCompletedFeed": {
                    const userFeedPath = `clientOrganizations/${this.organization}/userFeed/${this.getLoggedInUserId()}/courses/${journeyId !== null && journeyId !== void 0 ? journeyId : courseId}`;
                    const completeFeedPath = `clientOrganizations/${this.organization}/completedFeed/${this.getLoggedInUserId()}/courses/${journeyId !== null && journeyId !== void 0 ? journeyId : courseId}`;
                    const payload = Object.assign(Object.assign({}, userFeed), { createdAt: this.getServerTimestamp(), lang: null });
                    this.fbSet(completeFeedPath, payload);
                    /* @ts-ignore */
                    this.fbSet(userFeedPath, null);
                    this.resetCourseProgress({
                        courseId: courseId || "",
                        journeyId: journeyId,
                        shareId: shareId,
                        isLanguageSwitch: false,
                    });
                    break;
                }
                case "UpdateFinishCountUserFeed": {
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/userFeed/${this.getLoggedInUserId()}/courses/${journeyId}`
                        : `clientOrganizations/${this.organization}/userFeed/${this.getLoggedInUserId()}/courses/${courseId}`;
                    this.fbUpdate(path, { finishedCount: (0, database_1.increment)(1) });
                    break;
                }
                case "LMSAnalyticsCallback": {
                    const payload = Object.assign(Object.assign({}, analyticsCBPayload), { type: "callback", orgId: this.organization, st: this.getServerTimestamp(), knUserId: this.getLoggedInUserId() });
                    yield (0, LMSAnalytics_1.raiseLMSAnalytics)({ type: "LMSAnalyticsCallback", fbAPI: this, payload });
                    break;
                }
                case "ScromProgress": {
                    const { shareId: sShareId, courseId: sCourseId } = scromProgress || {};
                    path = journeyId
                        ? `clientOrganizations/${this.organization}/distinctScormResponsesLMS/${sCourseId}/${journeyId}/${sShareId}/${this.getLoggedInUserId()}`
                        : `clientOrganizations/${this.organization}/distinctScormResponsesLMS/${sCourseId}/${sShareId}/${this.getLoggedInUserId()}`;
                    const rpcLMSPath = `RPC/newLMSResponseRequest/request/${this.getPushKey()}`;
                    const rpcPayload = {
                        event: scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.event,
                        journeyId: null,
                        courseId: sCourseId,
                        moduleId: scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.lessonId,
                        cardId: scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.cardId,
                        nuggetType: "courses",
                        organization: this.organization,
                        subType: "scorm",
                        timestamp: this.getServerTimestamp(),
                        dt: scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.dt,
                        userId: this.getLoggedInUserId(),
                        sessionStart: scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.sessionId,
                        progress: ((_b = scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.event) === null || _b === void 0 ? void 0 : _b.progress) &&
                            JSON.parse((_c = scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.event) === null || _c === void 0 ? void 0 : _c.progress),
                    };
                    const setScromProgress = yield this.fbSet(path, ((_d = scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.event) === null || _d === void 0 ? void 0 : _d.progress) &&
                        JSON.parse((_e = scromProgress === null || scromProgress === void 0 ? void 0 : scromProgress.event) === null || _e === void 0 ? void 0 : _e.progress));
                    const setScromRpcProgress = yield this.fbSet(rpcLMSPath, rpcPayload);
                    yield Promise.all([setScromProgress, setScromRpcProgress]);
                    break;
                }
                case "SurveyAnswer":
                    const surveyPayload = {
                        cardId,
                        courseId,
                        journeyId: journeyId !== null && journeyId !== void 0 ? journeyId : null,
                        data,
                        dt,
                        moduleId: lessonId,
                        orgId: this.organization,
                        sessionId,
                        shareId,
                        st: this.getServerTimestamp(),
                        type: "survey",
                        uuid: this.getLoggedInUserId(),
                    };
                    path = `/LMSQuizResponseRequests/${this.getPushKey()}`;
                    this.fbSet(path, surveyPayload);
                    break;
                default:
                    break;
            }
        });
    }
    getProgressLMS(courseId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getValue(this.getMultiPathCommon(`progressLMS/${this.getLoggedInUserId()}/${courseId}`));
        });
    }
    getProgressQuizLMS(courseId) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.getValue(this.getMultiPathCommon(`progressQuizLMS/${this.getLoggedInUserId()}/${courseId}`));
        });
    }
    getLessonAttemptedCount({ courseId, shareId, lessonId, cardId, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const attemptedNode = `progressQuizLMS/${this.getLoggedInUserId()}/${courseId}/${shareId}/${lessonId}/cardProgress/${cardId}/attempted`;
            return this.getValue(this.getMultiPathCommon(attemptedNode));
        });
    }
    getScromProgress(courseId, shareId) {
        return __awaiter(this, void 0, void 0, function* () {
            const path = `distinctScormResponsesLMS/${courseId}/${shareId}/${this.getLoggedInUserId()}`;
            return this.getValue(this.getMultiPathCommon(path));
        });
    }
    getIsVideoAutoplayEnabled() {
        return __awaiter(this, void 0, void 0, function* () {
            const autoplayEnabled = `details/preferences/isAutoPlayVideo`;
            return this.getValue(this.getMultiPathCommon(autoplayEnabled));
        });
    }
    resetCourseProgress({ courseId, journeyId, shareId, journeyFinishCount = 0, isConsumed = false, isLanguageSwitch, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const journeyPath = `${journeyId}/${shareId}/${courseId}`;
            const progressLMSPath = `/clientOrganizations/${this.organization}/progressLMS/${this.getLoggedInUserId()}/${journeyId ? journeyPath : courseId}`;
            const UpdateFinishCountUserFeedPath = `clientOrganizations/${this.organization}/userFeed/${this.getLoggedInUserId()}/courses/${journeyId || courseId}`;
            if (journeyId && journeyFinishCount > 0) {
                if (isConsumed) {
                    this.fbUpdate(UpdateFinishCountUserFeedPath, {
                        finishedCount: journeyFinishCount - 1,
                    });
                }
            }
            else if (isLanguageSwitch) {
                this.fbUpdate(UpdateFinishCountUserFeedPath, {
                    finishedCount: 0,
                });
            }
            /* @ts-ignore */
            this.fbSet(progressLMSPath, null);
        });
    }
    uploadFile(file, path, progressCB) {
        return new Promise((res, rej) => {
            const fileNameSplits = file.name.split(".");
            const ext = fileNameSplits.pop();
            const uploadRef = (0, storage_1.ref)(this.storage, `${path}/${fileNameSplits.join(".")}${Date.now()}.${ext}`);
            const uploadTask = (0, storage_1.uploadBytesResumable)(uploadRef, file);
            uploadTask.on("state_changed", (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                progressCB(Math.min(90, progress));
            }, (error) => {
                rej(error);
            }, () => __awaiter(this, void 0, void 0, function* () {
                progressCB(100);
                const url = yield (0, storage_1.getDownloadURL)(uploadTask.snapshot.ref);
                res(url);
            }));
        });
    }
    uploadMultipleFiles(files, path, progressCB) {
        const progress = files.map(() => 0);
        progressCB(0);
        return Promise.all(files.map((file, fileIndex) => this.uploadFile(file, path, (singleProgress) => {
            progress[fileIndex] = singleProgress;
            const averageProgress = Math.round(progress.reduce((a, b) => a + b) / progress.length);
            progressCB(averageProgress);
        })));
    }
    raiseGamification(action, module, nuggetId) {
        return __awaiter(this, void 0, void 0, function* () {
            const types = (0, utils_1.getGamificationNuggetTypes)(module);
            if (!types) {
                return;
            }
            const { type, subType } = types;
            const points = yield this.getValue(this.getNodeRef(`${this.getCommonPath("gamifications")}/${type}/${subType}/${action}`));
            if (points !== null) {
                const requestId = `${nuggetId}-${this.getLoggedInUserId()}-${action}`;
                const payload = {
                    classificationType: type,
                    organization: this.organization,
                    userId: this.getLoggedInUserId(),
                    nuggetId,
                    event: action,
                    createdAt: this.getServerTimestamp(),
                    nuggetType: type,
                    points,
                };
                yield this.fbSet(`${this.getCommonPath("gamificationRequests")}/${requestId}`, payload);
            }
        });
    }
    raiseNuggetAnalytics(nuggetId, action) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`Raising analytics for ${action} ${nuggetId}`);
        });
    }
    getGroupMembers(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            const userIds = yield this.getValue(this.getChild(this.getNodeRef(this.getCommonPath("groupMembers")), groupId));
            return Promise.all((0, lodash_1.keys)(userIds).map((userId) => this.getUserDetails(userId)));
        });
    }
    searchUsersRPC(filterText, options) {
        var _b;
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.callRPCWithoutTimeout("RPC/search", Object.assign(Object.assign({}, options), { query: filterText, type: "users", isMobile: true }));
            return (_b = response.results) !== null && _b !== void 0 ? _b : [];
        });
    }
}
exports.FirebaseAPI = FirebaseAPI;
_a = FirebaseAPI;
FirebaseAPI.getTokenUserDetails = (authUser) => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield authUser.getIdToken();
    return JSON.parse(js_base64_1.Base64.decode(token.split(".")[1] || "{}"));
});

"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCourseUserFeedForPublicCourse = exports.getCourseUserFeed = void 0;
function getCourseUserFeed({ fbAPI, courseId }) {
    const feedRef = fbAPI.getLMSDetails('myCourseFeedById', courseId);
    return fbAPI.getValue(feedRef);
}
exports.getCourseUserFeed = getCourseUserFeed;
function setCourseUserFeedForPublicCourse({ fbAPI, courseId, authorId }) {
    return __awaiter(this, void 0, void 0, function* () {
        const userFeedPath = fbAPI.getMultiPath(`/userFeed/${fbAPI.getLoggedInUserId()}/courses/${courseId}`);
        const shareId = fbAPI.getPushKey();
        const directLaunchPayload = {
            "author": authorId,
            "classificationType": "courses",
            "createdAt": fbAPI.getServerTimestamp(),
            shareId: `wdl-${shareId}`,
            "shareType": 'WEB-DirectLaunch'
        };
        yield fbAPI.fbUpdate(userFeedPath, directLaunchPayload);
    });
}
exports.setCourseUserFeedForPublicCourse = setCourseUserFeedForPublicCourse;

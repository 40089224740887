"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.raiseReturnFormResponseRequest = exports.raiseShareFormResponseToLocationLeaderRequest = exports.getFormPaperTrail = exports.getFormResponse = exports.sendToNextFormResponse = exports.submitFormResponses = exports.deleteDraft = exports.writeFormResponses = exports.initiateNewResponse = exports.getLocation = void 0;
const __1 = require("..");
const Share_1 = require("../Share");
const utils_1 = require("../utils");
Object.defineProperty(exports, "getLocation", { enumerable: true, get: function () { return utils_1.getLocation; } });
const initiateNewResponse = (dbAPi, formId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const newResponseRef = yield dbAPi.pushToNode(`${dbAPi.getCommonPath("currentUserFormResponses")}/${formId}`, { startedAt: dbAPi.getServerTimestamp() });
    const responseId = (_a = newResponseRef.key) !== null && _a !== void 0 ? _a : Date.now() + "";
    yield dbAPi.fbSet(`${dbAPi.getCommonPath("currentUserFormDrafts")}/${formId}/${responseId}`, dbAPi.getServerTimestamp());
    return responseId;
});
exports.initiateNewResponse = initiateNewResponse;
const writeFormResponses = (dbAPi, formId, responseId, userId, response) => __awaiter(void 0, void 0, void 0, function* () {
    yield dbAPi.fbUpdate(`${dbAPi.getCommonPath("formUserResponses")}/${userId}/${formId}/${responseId}/responses`, response);
});
exports.writeFormResponses = writeFormResponses;
const updateUserFormResponse = (dbApi, formId, responseId, userId, update) => __awaiter(void 0, void 0, void 0, function* () {
    const responsePath = `${dbApi.getCommonPath("formUserResponses")}/${userId}/${formId}/${responseId}`;
    return dbApi.fbUpdate(responsePath, update);
});
const setUserFormResponse = (dbApi, formId, responseId, userId, update) => __awaiter(void 0, void 0, void 0, function* () {
    const responsePath = `${dbApi.getCommonPath("formUserResponses")}/${userId}/${formId}/${responseId}`;
    return dbApi.fbSet(responsePath, update);
});
const removeDraft = (dbApi, formId, responseId) => {
    const draftPath = `${dbApi.getCommonPath("currentUserFormDrafts")}/${formId}/${responseId}`;
    return dbApi.fbSet(draftPath, null);
};
const deleteDraft = (dbApi, formId, responseId) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    yield Promise.all([
        removeDraft(dbApi, formId, responseId),
        setUserFormResponse(dbApi, formId, responseId, (_b = dbApi.getLoggedInUserId()) !== null && _b !== void 0 ? _b : 'user-id', null),
    ]);
});
exports.deleteDraft = deleteDraft;
const updateActionOn = (dbApi, { formId, responseId, isWF = false, }) => {
    if (!isWF) {
        const actionOnPath = `${dbApi.getCommonPath("currentUserFormLookups")}/actionOn`;
        return dbApi.fbSet(`${actionOnPath}/${formId}/lastFilled`, dbApi.getServerTimestamp());
    }
    else {
        const actionOnPath = `${dbApi.getCommonPath("currentUserFormLookups")}/actionOn/${formId}-${responseId}`;
        return dbApi.fbSet(actionOnPath, null);
    }
};
const removeReceivedResponses = (dbApi, formId, responseId) => {
    const receivedResponsesPath = `${dbApi.getCommonPath("currentUserFormReceivedResponses")}/${formId}/${responseId}`;
    return dbApi.fbSet(receivedResponsesPath, null);
};
const removeReceivedForms = (dbApi, formId, responseId) => {
    const receivedFormsPath = `${dbApi.getCommonPath("currentUserFormReceivedForms")}/${formId}/status/${responseId}`;
    return dbApi.fbSet(receivedFormsPath, null);
};
const submitFormResponses = (dbAPi, formId, responseId, { requireLocation, isRejected = false, isWF = false, initiatorId: _initiatorId, onLocationAttempt, onLocationAcquired, onLocationRejected, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const update = {
        isSubmitted: true,
        tzOffset: (0, utils_1.getUtcOffsetSec)(),
        submittedAt: dbAPi.getServerTimestamp(),
        lastSubmitterId: dbAPi.getLoggedInUserId(),
        st: dbAPi.getServerTimestamp(),
        tz: (0, utils_1.getTimezoneIdentifier)(),
        rejectedBy: isRejected ? dbAPi.getLoggedInUserId() : null,
    };
    if (requireLocation) {
        try {
            const [currentState] = yield (0, utils_1.checkForLocationPermission)({
                onGranted: onLocationAcquired,
            });
            if (currentState === "prompt") {
                onLocationAttempt && onLocationAttempt();
            }
            if (currentState === "denied") {
                throw new Error("LocationNotAllowed");
            }
            const location = yield (0, utils_1.getLocation)();
            update.location = location;
            onLocationAcquired && onLocationAcquired();
        }
        catch (err) {
            if (err.message === "LocationNotAvailable") {
                update.location = {
                    message: "No location util found",
                };
            }
            else {
                if (err.message === "LocationNotAllowed") {
                    onLocationRejected && onLocationRejected();
                }
                throw err;
            }
        }
    }
    const initiatorId = (_c = _initiatorId !== null && _initiatorId !== void 0 ? _initiatorId : dbAPi.getLoggedInUserId()) !== null && _c !== void 0 ? _c : 'user-id';
    yield Promise.all([
        updateUserFormResponse(dbAPi, formId, responseId, initiatorId, update),
        removeDraft(dbAPi, formId, responseId),
        updateActionOn(dbAPi, { formId, isWF, responseId }),
        dbAPi.raiseGamification("submitted", "form", formId),
        (0, __1.raiseAnalytics)({
            fbAPI: dbAPi,
            type: "consumed",
            formItem: {
                id: formId,
                createdAt: Date.now(),
                category: "",
                name: "",
            },
        }),
        removeReceivedResponses(dbAPi, formId, responseId),
        removeReceivedForms(dbAPi, formId, responseId),
    ]);
});
exports.submitFormResponses = submitFormResponses;
const sendToNextFormResponse = (dbAPi, formId, responseId, shareRequest, { sectionId, requireLocation, initiatorId: _initiatorId, isWF = false, onLocationAttempt, onLocationAcquired, onLocationRejected, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    const update = {
        tzOffset: (0, utils_1.getUtcOffsetSec)(),
        userId: dbAPi.getLoggedInUserId(),
        st: dbAPi.getServerTimestamp(),
        lastSubmittedAt: dbAPi.getServerTimestamp(),
        sectionId: sectionId !== null && sectionId !== void 0 ? sectionId : null,
        tz: (0, utils_1.getTimezoneIdentifier)(),
    };
    if (requireLocation) {
        try {
            onLocationAttempt && onLocationAttempt();
            const location = yield (0, utils_1.getLocation)();
            update[`locations/${sectionId}`] = location;
            onLocationAcquired && onLocationAcquired();
        }
        catch (err) {
            if (err.message === "LocationNotAvailable") {
                update[`locations/${sectionId}`] = {
                    message: "No location util found",
                };
            }
            else {
                if (err.message === "LocationNotAllowed") {
                    onLocationRejected && onLocationRejected();
                }
                throw err;
            }
        }
    }
    const initiatorId = (_d = _initiatorId !== null && _initiatorId !== void 0 ? _initiatorId : dbAPi.getLoggedInUserId()) !== null && _d !== void 0 ? _d : 'user-id';
    const sharePayload = Object.assign({ initiatorId,
        responseId }, shareRequest);
    yield Promise.all([
        updateUserFormResponse(dbAPi, formId, responseId, initiatorId, update),
        (0, Share_1.shareNugget)(dbAPi, "form", formId, sharePayload),
        updateActionOn(dbAPi, { formId, isWF, responseId }),
        removeDraft(dbAPi, formId, responseId),
        dbAPi.raiseGamification("sendToNext", "form", formId),
        //TODO: Implement this
        removeReceivedResponses(dbAPi, formId, responseId),
        removeReceivedForms(dbAPi, formId, responseId),
    ]);
});
exports.sendToNextFormResponse = sendToNextFormResponse;
const getFormResponse = (fbAPI, formId, initiatorId, responseId) => {
    const formResponseRef = fbAPI.getNodeRef(fbAPI.getCommonPath("formUserResponses"));
    return fbAPI.getValue(fbAPI.getChild(formResponseRef, `${initiatorId}/${formId}/${responseId}`));
};
exports.getFormResponse = getFormResponse;
const getFormPaperTrail = (fbAPI, formId, responseId) => {
    const formResponseRef = fbAPI.getNodeRef(fbAPI.getCommonPath("formPaperTrails"));
    return fbAPI.getValue(fbAPI.getChild(formResponseRef, `${formId}/${responseId}`));
};
exports.getFormPaperTrail = getFormPaperTrail;
const raiseShareFormResponseToLocationLeaderRequest = (fbAPI, formId, responseId, { userId, userName }) => {
    const payload = {
        type: "form",
        status: "notifyLeaders",
        userId,
        nuggetId: formId,
        responseId: responseId,
        userName,
        organization: fbAPI.organization,
    };
    return fbAPI.pushToNode(`statusChangeRequests`, payload);
};
exports.raiseShareFormResponseToLocationLeaderRequest = raiseShareFormResponseToLocationLeaderRequest;
const raiseReturnFormResponseRequest = (fbAPI, formId, responseId, { userId, returnMessage, sectionId }) => {
    const payload = {
        createdAt: fbAPI.getServerTimestamp(),
        formId,
        organization: fbAPI.organization,
        responseId,
        returnMessage,
        userId,
        sectionId,
    };
    return fbAPI.pushToNode(`/RPC/forms/request`, payload);
};
exports.raiseReturnFormResponseRequest = raiseReturnFormResponseRequest;

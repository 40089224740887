"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.listenForMessages = exports.registerNotificationToken = void 0;
const messaging_1 = require("firebase/messaging");
const vapidKey = 'BOB6VrV7DCRw4v92PA3tn8VN3ZSyOvop8QgZhXmxY3R27K5wJ2CVgHvCEmKHkjyWpmelYxwPoGjx7z2FQkNFNCs';
function requestPermission() {
    return __awaiter(this, void 0, void 0, function* () {
        console.log('Requesting permission...');
        return new Promise((resolve, _reject) => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    resolve(true);
                }
                else {
                    resolve(false);
                }
            });
        });
    });
}
function registerNotificationToken(scoobyAPI, fbAPI) {
    return __awaiter(this, void 0, void 0, function* () {
        const messaging = (0, messaging_1.getMessaging)(scoobyAPI.app);
        try {
            const token = yield (0, messaging_1.getToken)(messaging, { vapidKey });
            if (token) {
                console.log(token);
                // Update to FB with web push notification token
                yield fbAPI.fbSet(`/deviceIds/${fbAPI.getLoggedInUserId()}/web`, token);
                listenForMessages(messaging);
            }
            else {
                const granted = yield requestPermission();
                if (granted) {
                    yield registerNotificationToken(scoobyAPI, fbAPI);
                }
            }
        }
        catch (err) {
            console.log(err, err === null || err === void 0 ? void 0 : err.stack);
        }
    });
}
exports.registerNotificationToken = registerNotificationToken;
function listenForMessages(messaging) {
    (0, messaging_1.onMessage)(messaging, (payload) => {
        const { data: { title, body } = {} } = payload;
        const notificationTitle = title;
        // new Notification(notificationTitle, { data: body });
    });
}
exports.listenForMessages = listenForMessages;

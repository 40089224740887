"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.raiseAnalytics = exports.getUserFormsFeedPath = exports.FORMS_ANALYTICS_NODE_PATH = void 0;
const database_1 = require("firebase/database");
const transformers_1 = require("@know/transformers");
exports.FORMS_ANALYTICS_NODE_PATH = "/analyticsRequest";
function getUserFormsFeedPath(fbAPI, formId = '') {
    const userId = fbAPI.getLoggedInUserId();
    return fbAPI.getMultiPath(`forms/user_forms/${userId}/${formId}`);
}
exports.getUserFormsFeedPath = getUserFormsFeedPath;
function raiseAnalytics(_a) {
    var { fbAPI } = _a, props = __rest(_a, ["fbAPI"]);
    return __awaiter(this, void 0, void 0, function* () {
        const userId = fbAPI.getLoggedInUserId();
        if (userId !== null) {
            if (props.type === 'opened') {
                if (!props.formItem.isRead) {
                    return yield fbAPI.fbUpdate(getUserFormsFeedPath(fbAPI, props.formItem.id), {
                        isRead: true
                    });
                }
            }
            else {
                yield fbAPI.pushToNode(exports.FORMS_ANALYTICS_NODE_PATH, (0, transformers_1.getAnalyticsPayload)(Object.assign(Object.assign({}, props), { userId: userId || "", organization: fbAPI.organization, serverTimestamp: database_1.serverTimestamp })));
            }
        }
    });
}
exports.raiseAnalytics = raiseAnalytics;
